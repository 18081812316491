import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  CardContent,
  Typography,
  Card,
  Link,
} from "@mui/material";
import axios from "axios";
 
function App() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  // //const shortUrlCode = "oxzt3w1qS8";
  const shortUrlCode = window.location.pathname.substring(1);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  const sendGTA = (type) => {
    gtag("event", "page_view", {
      navigationType: type + "_Navigate",
      shortUrlCode: shortUrlCode,
      date_time: new Date().getTime(),
    });
  };

  const getLinkData = async () => {
    setLoading(true);

    try {
      const url =
        "https://" +
        process.env?.REACT_APP_API_URL +
        "/v1/twilio-conversation-api/resolveurl/" +
        shortUrlCode;

      const res = await axios.get(url);
      setLoading(false);
      if (res?.data?.success) {
        setData(res?.data);
        setTimeout(async () => {
          sendGTA("Auto");
          window.location = res?.data?.data;
        }, 1000);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const gtagFunc = () => {
    const gtmId = process.env?.REACT_APP_GTM;
    const body = document.getElementsByTagName("body")[0];
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
    script.async = true;
    script.onload = function () {
      gtag("js", new Date());
      gtag("config", gtmId, {
        page_title: "homepage",
      });
    };

    body.appendChild(script);
  };
 

  useEffect(() => {
    gtagFunc();
    if (shortUrlCode.trim().length > 0 && !data?.data && !loading) {
      getLinkData();
    }
  }, []);

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "300px",
      }}
      container
    >
      <Grid xs={6} item>
        <Card>
          <CardContent sx={{ textAlign: "center" }}>
            {(loading || data?.data) && <CircularProgress />}
            {loading && (
              <Typography gutterBottom variant="h5" component="div">
                Please wait
              </Typography>
            )}
            {data?.data && data?.success && (
              <Typography variant="body2" color="text.secondary">
                You are being redirected to this <br />
                <Link
                  onClick={() => {
                    sendGTA("Manual");
                  }}
                  sx={{ fontSize: "18px" }}
                  href={data?.data}
                >
                  {data?.data}
                </Link>
              </Typography>
            )}
            {((data && !data?.success) || !shortUrlCode) && (
              <Typography variant="body2" color="text.secondary">
                Either the link has expired and it is invalid. Please contact
                the provider.
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default App;
